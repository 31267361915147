import { Injectable, inject } from "@angular/core";
import { BarcodeScanner } from "@capacitor-mlkit/barcode-scanning";
import { Platform } from '@ionic/angular/standalone';
import { ReplaySubject } from "rxjs";
import { MessageController } from "src/infrastructure/message.controller";

@Injectable({ providedIn: 'root' })
export class BarcodeService {
    private platform = inject(Platform);
    private messageController = inject(MessageController);
    readonly requests$$ = new ReplaySubject<(value: string | undefined) => void>();

    async useNative() {
        const { barcodes } = await BarcodeScanner.scan();
        return barcodes[0]?.rawValue;
    }
    async useWeb() {
        return await new Promise<string | undefined>((resolve) => {
            this.requests$$.next(resolve);
        });
    }

    private method?: () => Promise<string | undefined>;
    async getBarcodeValue() {
        if (this.method === undefined) {
            await this.platform.ready();
            // if (this.platform.is('android') || this.platform.is('ios')) {
            //     const granted = await this.requestPermissions();
            //     if (granted) {
            //         this.method = this.useNative;
            //         return this.method();
            //     }
            //     await this.messageController.warning('Please grant camera permission to use the native barcode scanner.');
            //     return undefined;
            // }
            this.method = this.useWeb;
        }
        return this.method();
    }

    async requestPermissions(): Promise<boolean> {
        const { camera } = await BarcodeScanner.requestPermissions();
        return camera === 'granted' || camera === 'limited';
    }

}

